<script lang="ts" setup>
import { ImportSummaryType } from '@workflow'
import type { ImportSummary } from './types'

const props = defineProps<{ summaries: ImportSummary[] }>()

const colorMap = {
  [ImportSummaryType.Ready]: {
    number: 'text-green-500',
    background: 'bg-green-100 border-green-600',
    hover: 'hover:border-green-600',
  },
  [ImportSummaryType.Processing]: {
    number: 'text-yellow-500',
    background: 'bg-yellow-100 border-yellow-600',
    hover: 'hover:border-yellow-600',
  },
  [ImportSummaryType.Failed]: {
    number: 'text-red-500',
    background: 'bg-red-100 border-red-600',
    hover: 'hover:border-red-600',
  },
}

function onClick(summary: ImportSummary): void {
  useAnalytics({
    name: 'Workflow tab change',
    showDivision: true,
    data: {
      Value: summary.type,
    },
  })
}
</script>

<template>
  <ButtonGroup>
    <ButtonGroupItem
      v-for="summary in summaries"
      :key="summary.type"
      :to="summary.to"
      :exact="true"
      @click="onClick(summary)"
    >
      <div class="flex items-center text-gray-100">
        <span>{{ summary.label }}:</span>

        <span
          class="ml-1 text-lg font-bold"
          :class="colorMap[summary.type].number"
        >
          <AnimateNumberWrapper :number="summary.number" />
        </span>
      </div>
    </ButtonGroupItem>
  </ButtonGroup>
</template>
